// tableData.ts
interface TableDataItem {
   id: string;
   visible: boolean;
   job: JobFolder;
}

export enum JobFolder {
   KOA = 'Koa',
   CARD_2 = 'Card 2',
   CARD_3 = 'Card 3',
   INFINITY_BOX = 'Infinity Box',
   LOVE_CARD = 'Love Card',
   ICELAND_BOOK = 'Iceland Book',
   THANK_YOU_CARD = "Thank You Card",
   STOLEN_BANKER_BOOK = "Stolen Banker Book",
   WEDDING_1 = "Wedding 1",
   WEDDING_2 = "Wedding 2",
   WEDDING_3 = "Wedding 3",
   WEDDING_4 = "Wedding 4",
   SCODIX_PROVEN = "Future Proven"
}

const LibraryData: TableDataItem[] = [
   { id: '1', visible: true, job: JobFolder.CARD_3 },
   { id: '2', visible: true, job: JobFolder.INFINITY_BOX },
   { id: '3', visible: true, job: JobFolder.KOA },
   { id: '4', visible: true, job: JobFolder.LOVE_CARD },
   { id: '5', visible: true, job: JobFolder.ICELAND_BOOK },
   { id: '6', visible: true, job: JobFolder.SCODIX_PROVEN },
   { id: '7', visible: true, job: JobFolder.THANK_YOU_CARD },
   { id: '8', visible: true, job: JobFolder.STOLEN_BANKER_BOOK },
   { id: '9', visible: true, job: JobFolder.WEDDING_1 },
   { id: '10', visible: true, job: JobFolder.WEDDING_2 },
   { id: '11', visible: true, job: JobFolder.WEDDING_3 },
   { id: '12', visible: true, job: JobFolder.WEDDING_4 },
   
   
   // Add more data as needed
];

export interface LibraryComponentProps {
   LibraryData: { visible: boolean; id: string; job: JobFolder }[];
   onCellClick: (job: JobFolder) => void; // Function to handle cell click
}

export default LibraryData;
