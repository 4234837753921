import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Container, CssBaseline, Paper, TextField, Typography, useTheme } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
//import logo from '../../assets/images/ScodixLogo.png'
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import api from '../../api';

interface FormValues {
   email: string;
   password: string;
}

const version = process.env.REACT_APP_VERSION;

const schema = yup
   .object({
      email: yup.string().email('Email must be a valid email').required('Email is a required field'),
      password: yup.string().max(255).required('Password is a required field'),
   })
   .required();

const Login = (): JSX.Element => {
   const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
   } = useForm<FormValues>({
      resolver: yupResolver(schema),
   });
   const theme = useTheme();
   const navigate = useNavigate();

   const onSubmit: SubmitHandler<FormValues> = async (data) => {
      try {
         const response = await api.auth.login(data);

         if (response.accessToken !== '') {
            sessionStorage.setItem('accessToken', response.accessToken);
         }
         toast.success('Login Success');
         navigate('/preview');
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log(error);
         toast.error('Wrong credentials provided');
         localStorage.removeItem('userSettings');
         sessionStorage.removeItem('accessToken');
      }
   };

   return (
      <Container
         component='main'
         maxWidth='sm'
         sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
         }}
      >
         <CssBaseline />
         <Paper
            square
            elevation={2}
            sx={{
               px: 3,
               py: 4,
               boxShadow: 'none !important',
               background: '#1b1b1b',

               [theme.breakpoints.down(780)]: {
                  margin: 1,
               },
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
               }}
            >
               <Box>
                  <img src={''} alt='' height={30} />
               </Box>
               <Box
                  component='form'
                  noValidate
                  sx={{
                     mt: 2,
                     [theme.breakpoints.up(720)]: {
                        width: 400,
                     },
                  }}
                  onSubmit={handleSubmit(onSubmit)}
               >
                  <Typography display='block' color='#ffffff'>Email</Typography>
                  <TextField
                     error={errors.email !== undefined ?? false}
                     helperText={errors?.email?.message}
                     margin='normal'
                     required
                     fullWidth
                     autoFocus
                     {...register('email')}
                     sx={{ marginTop: 0 }}
                  />
                  <Typography display='block' color='#ffffff' sx={{ marginTop: 1 }}>
                     Password
                  </Typography>
                  <TextField
                     autoComplete='off'
                     error={errors.password !== undefined ?? false}
                     helperText={errors?.password?.message}
                     margin='normal'
                     required
                     fullWidth
                     type='password'
                     {...register('password')}
                     sx={{ marginTop: 0 }}
                  />
                  <LoadingButton
                     type='submit'
                     style={{ 
                        color: '#1b1b1b',
                        fontWeight: 'bold'
                     }}
                     fullWidth
                     loading={isSubmitting}
                     disabled={isSubmitting}
                     sx={{ marginTop: 2 }}
                     variant='contained'
                  >
                     Login
                  </LoadingButton>
               </Box>
            </Box>
            {/* Add the small line of text here */}
            <p
               style={{
                  textAlign: 'center',
                  color: 'gray',
                  fontSize: '14px',
                  padding: '5px',
                  fontFamily: 'Arial',
               }}
            >
               Version {version}
            </p>
         </Paper>
      </Container>
   );
};

export default Login;
