/* eslint-disable no-console */
import { updateBlackPxTextState, updateSettings } from '../state/general/general.slice';
import { type BlackPxTextTypes } from '../state/general/general.types';
import store from '../store/store';

class WebsocketWrap {
   private ws: WebSocket | null;
   private readonly url: string;

   constructor() {
      this.url = 'wss://bdf8ekt05h.execute-api.eu-central-1.amazonaws.com/production';
      this.ws = null;
   }

   async connect(): Promise<WebSocket> {
      return await new Promise((resolve, reject) => {
         if (this.ws) {
            resolve(this.ws);
            return this.ws;
         }

         this.ws = new WebSocket(this.url);

         this.ws.onopen = (event) => {
            if (event && this.ws) {
               this.ws.send(JSON.stringify({ action: 'message' }));
               console.log('Connected');
               resolve(this.ws);
            }
         };
         this.ws.onclose = () => {
            console.log('Disconnected');
         };
         this.ws.onerror = (event) => {
            console.log('error', event);
            // reject(event); // aws websocket do not send open event, instead it sends error always
         };
         this.ws.onmessage = this.onMessage;
      });
   }

   disconnect(): void {
      if (this.ws) {
         this.ws.close();
         this.ws = null;
      }
   }

   private readonly onMessage = (event: WebSocketEventMap['message']): void | Promise<void> => {
      let message: any = {};

      try {
         message = JSON.parse(event.data);
         if (message?.connectionId) {
            this.handleConnectionId(message);
         }

         if (message?.JobStatus) {
            this.handleJobStatus(message);
         }

         if (typeof message === 'string') {
            const internal = JSON.parse(message);

            if (internal?.AIJobStatus) {
               this.handleAIJobStatus(internal);
            }

            if (internal?.['scodix_ai_folder_name']) {
               this.handleFolderName(internal);
            }

            if (internal?.['black_px_text']) {
               this.handleBlackPxText(internal);
            }
         }
      } catch (error) {
         console.error(error);
         console.error('Empty WS message');
         message = {};
      }
   };

   private readonly handleConnectionId = (message: any): void => {
      console.log('[CONNECTION ID]: ', message.connectionId);
      store.dispatch(updateSettings({ connectionId: message.connectionId }));
   };

   private readonly handleJobStatus = (message: any): void => {
      console.log('[JOB STATUS CHANGE]: ', message.JobStatus);
      store.dispatch(updateSettings({ jobStatus: message.JobStatus }));
   };

   private readonly handleAIJobStatus = (message: any): void => {
      console.log('[AI JOB STATUS CHANGE]: ', message.AIJobStatus);
      store.dispatch(updateSettings({ jobStatus: message.AIJobStatus }));
   };

   private readonly handleFolderName = (message: any): void => {
      console.log('[AI FOLDER NAME]: ', message['scodix_ai_folder_name']);
      store.dispatch(updateSettings({ folderName: message['scodix_ai_folder_name'] }));
   };

   private readonly handleBlackPxText = (data: BlackPxTextTypes): void => {
      console.log('[BLACK PX TEXT]: ', data);
      store.dispatch(updateBlackPxTextState(data));
   };
}

const instance = new WebsocketWrap();
export default instance;
