import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from 'store/store';

import {
   type BlackPxTextTypes,
   EnumImageType,
   type FileSizeTypes,
   type GeneralState,
   JOB_STATUS,
   type SelectedImageView,
   type Settings,
   type ThreeJSDataTypes,
} from './general.types';

const initialState: GeneralState = {
   flag: false,
   settings: {
      connectionId: '',
      folderName: '',
      jobStatus: JOB_STATUS.INITIAL,
   },
   blackPxText: null,
   tableNumberOfCopies: 1000,
   fileSizeTypes: 'default',
   decodedThreeJSData: {
      printBW: '',
      printColor: '',
   },
   selectedImageView: '',
   imageTypeUrl: {
      [EnumImageType.Merge]: '',
      [EnumImageType.OCR]: '',
      [EnumImageType.Segmentation]: '',
      [EnumImageType.CMYK]: '',
      [EnumImageType.Foil]: '',
   },
   isTableVisible: false,
   isLibraryVisible: false,
};

const generalSlice = createSlice({
   name: 'general',
   initialState,
   reducers: {
      resetState: (state) => {
         Object.assign(state, initialState);
      },
      setFlag: (state, action: PayloadAction<boolean>) => {
         state.flag = action.payload; // state here can be mutable, because redux toolkit makes it immutable under the hood. no worries about it
      },
      updateSettings: (state, action: PayloadAction<Partial<Settings>>) => {
         // eslint-disable-next-line no-console
         console.log('ACT', action.payload);
         state.settings = { ...state.settings, ...action.payload };
      },

      updateBlackPxTextState: (state, action: PayloadAction<BlackPxTextTypes>) => {
         return {
            ...state,
            blackPxText: action.payload,
         };
      },

      setNumberOfCopies: (state, action: PayloadAction<number>) => {
         return {
            ...state,
            tableNumberOfCopies: action.payload,
         };
      },

      setFileSizeType: (state, action: PayloadAction<FileSizeTypes>) => {
         return {
            ...state,
            fileSizeTypes: action.payload,
         };
      },

      setDecodedImageData: (state, action: PayloadAction<ThreeJSDataTypes>) => {
         return {
            ...state,
            decodedThreeJSData: action.payload,
         };
      },

      resetDecodedImageData: (state) => {
         return {
            ...state,
            decodedThreeJSData: {
               printBW: '',
               printColor: '',
            },
         };
      },

      selectImageView: (state, action: PayloadAction<SelectedImageView>) => {
         return {
            ...state,
            selectedImageView: action.payload,
         };
      },

      setImageTypeUrl: (state, action: PayloadAction<{ imageTypeUrl: EnumImageType; url: string }>) => {
         const { imageTypeUrl, url } = action.payload;
         return {
            ...state,
            imageTypeUrl: {
               ...state.imageTypeUrl,
               [imageTypeUrl]: url,
            },
         };
      },

      resetImageTypeUrl: (state) => {
         return {
            ...state,
            imageTypeUrl: {
               [EnumImageType.Merge]: '',
               [EnumImageType.OCR]: '',
               [EnumImageType.Segmentation]: '',
               [EnumImageType.CMYK]: '',
               [EnumImageType.Foil]: '',
            },
         };
      },

      changeTableVisibility(state, action: PayloadAction<boolean>) {
         return {
            ...state,
            isTableVisible: action.payload,
         };
      },

      changeLibraryVisibility(state, action: PayloadAction<boolean>) {
         return {
            ...state,
            isLibraryVisible: action.payload,
         };
      },
   },
});

export const stateOfGeneralSlice = (state: RootState) => state.general;
export const {
   resetState,
   setFlag,
   updateSettings,
   updateBlackPxTextState,
   setNumberOfCopies,
   setFileSizeType,
   setDecodedImageData,
   selectImageView,
   setImageTypeUrl,
   resetImageTypeUrl,
   resetDecodedImageData,
   changeTableVisibility,
   changeLibraryVisibility,
} = generalSlice.actions;

export default generalSlice.reducer;
