import { Navigate } from 'react-router-dom';

interface Props {
   component: React.ComponentType;
   path?: string;
}

const PrivateRoute = ({ component: RouteComponent }: Props): JSX.Element => {
   const isAuthenticated = sessionStorage.getItem('accessToken');

   if (isAuthenticated !== null && isAuthenticated !== '') {
      return <RouteComponent />;
   }

   return <Navigate to='/' />;
};

export default PrivateRoute;
