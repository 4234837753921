import React from 'react';

import {
   CalculateOutlined,
   CloudUploadOutlined,
   FontDownloadOutlined,
   GrainOutlined,
   ImageOutlined,
   LibraryAddOutlined,
   PictureAsPdfOutlined,
   ThreeDRotationOutlined,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useToolbarLogic } from 'components/toolbar/toolbar.logic';
import { useToolbarStyles } from 'components/toolbar/toolbar.styles';
import { ImageViewButtonTypes } from 'components/toolbar/toolbar.types';

export const Toolbar = () => {
   const { toolbarBox, toolbarButton, ChangeImageViewButton, style } = useToolbarStyles();
   const {
      handleInputClick,
      inputRef,
      isTableVisible,
      isLibraryVisible,
      handleFileInputChange,
      handleSelectImageView,
      selectedImageView,
      handleChangeTableVisibility,
      handleChangeLibraryVisibility,
      isFileUploading,
   } = useToolbarLogic();

   const imageViewButtons: ImageViewButtonTypes[] = [
      { view: 'Embellishment Options', id: 1, icon: <PictureAsPdfOutlined /> },
      { view: 'Text & Objects', id: 2, icon: <ImageOutlined /> },
      { view: 'text', id: 3, icon: <FontDownloadOutlined /> },
      { view: 'objects', id: 4, icon: <GrainOutlined /> },
      { view: 'Preview', id: 5, icon: <ThreeDRotationOutlined /> },
   ];

   return (
      <Box component='div' sx={toolbarBox}>
         <Typography>Tools</Typography>
         <Box sx={toolbarButton} component='div' onClick={handleInputClick}>
            <CloudUploadOutlined /> Upload & Analyze
            <input
               type='file'
               accept='.pdf, image/*'
               hidden
               ref={inputRef}
               onChange={handleFileInputChange}
            />
         </Box>

         {imageViewButtons.map(({ id, view, icon }) => {
            const nestedButtons = id === 2 || id === 3 || id === 4;
            const parentButton = id === 1;

            return (
               <ChangeImageViewButton
                  key={id}
                  style={{ color: '#fff'}}
                  disabled={isFileUploading}
                  selected={selectedImageView === view}
                  onClick={() => handleSelectImageView(view)}
                  sx={{
                     ...(parentButton && style.parentButtonLine),
                     ...(nestedButtons && style.childrenButtonLine),
                  }}
               >
                  {icon}
                  {view}
               </ChangeImageViewButton>
            );
         })}
         {/* <ChangeImageViewButton
            style={{ color: '#fff'}}
            disabled={isFileUploading}
            selected={isTableVisible}
            onClick={() => handleChangeTableVisibility()}
         >
            <CalculateOutlined />
            Pricing
         </ChangeImageViewButton> */}

         <ChangeImageViewButton
            style={{ color: '#fff'}}
            disabled={isFileUploading}
            selected={isLibraryVisible}
            onClick={() => handleChangeLibraryVisibility()}
         >
            <LibraryAddOutlined />
            Library
         </ChangeImageViewButton>
      </Box>
   );
};
