import { type TableDataTypes } from 'components/table/types';

export const initialTableData = [
   { id: 1, mode: 'HD8', multiplyMethod: 'black_px_merge', perCopy: 0, numberOfCopies: 0, visible: true },
   { id: 2, mode: 'HD50', multiplyMethod: 'black_px_merge', perCopy: 0, numberOfCopies: 0, visible: true },
   { id: 3, mode: 'HD80', multiplyMethod: 'black_px_merge', perCopy: 0, numberOfCopies: 0, visible: true },

   { id: 4, mode: 'HD8', multiplyMethod: 'black_px_text', perCopy: 0, numberOfCopies: 0, visible: true },
   { id: 5, mode: 'HD50', multiplyMethod: 'black_px_text', perCopy: 0, numberOfCopies: 0, visible: true },
   { id: 6, mode: 'HD80', multiplyMethod: 'black_px_text', perCopy: 0, numberOfCopies: 0, visible: true },

   {
      id: 7,
      mode: 'HD8',
      multiplyMethod: 'black_px_segmentation',
      perCopy: 0,
      numberOfCopies: 0,
      visible: true,
   },
   {
      id: 8,
      mode: 'HD50',
      multiplyMethod: 'black_px_segmentation',
      perCopy: 0,
      numberOfCopies: 0,
      visible: true,
   },
   {
      id: 9,
      mode: 'HD80',
      multiplyMethod: 'black_px_segmentation',
      perCopy: 0,
      numberOfCopies: 0,
      visible: true,
   },
] as TableDataTypes[];

export const radioButtons = [
   { id: 1, label: 'Default', value: 'default' },
   { id: 2, label: 'B2', value: 'B2' },
   { id: 3, label: 'B1', value: 'B1' },
];

export const imageSizeMap = {
   default: {
      merge: 'Merge_results.png',
      ocr: 'OCR.png',
      segmentation: 'Segmentation.png',
      cmyk: 'Cmyk.png',
   },
   B1: {
      merge: 'Merge_results_B1.png',
      ocr: 'OCR_B1.png',
      segmentation: 'Segmentation_B1.png',
      cmyk: 'Cmyk_B1.png',
   },
   B2: {
      merge: 'Merge_results_B2.png',
      ocr: 'OCR_B2.png',
      segmentation: 'Segmentation_B2.png',
      cmyk: 'Cmyk_B2.png',
   },
};
