import { createTheme } from '@mui/material';

export const scodixAiTheme = createTheme({
   palette: {
      primary: {
         main: '#e0c78d',
         light: '#AAE8DE',
         dark: '#bc9d54',
      },
      divider: '#e5e7eb',
   },
   components: {
      MuiCssBaseline: {
         styleOverrides: {
           body: {
               backgroundColor: '#0b0b0b', 
               color: '#fff', 
           },
         },
      },
      MuiAppBar: {
         styleOverrides: {
            root: {
               '&.PrimaryAppbar': {
                  background: '#0b0b0b',
                  // background: '#fff',
                  boxShadow: 'none',
                  padding: '10px 28px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid rgb(229,231,235,1)',
               },
            },
         },
      },
      MuiTypography: {
         styleOverrides: {
            root: {
               '&.ScodixAI': {
                  color: '#BEA771',
                  fontWeight: 600,
                  fontSize: '23px',
               },
               '&.DragFile': {
                  color: '#343434',
                  fontWeight: 500,
                  fontSize: '16px',
               },
               '&.TextFieldLabel': {
                  color: '#A6A6A6',
                  fontSize: '13px',
                  fontWeight: 400,
               },
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               fontSize: '16px',
               color: 'red',
               '&.UploadFile': {
                  color: '#BEA771',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
               },
            },
         },
      },
      MuiAvatar: {
         styleOverrides: {
            root: {
               '&.UserAvatar': {
                  background: '#e0c78d',
                  width: '40px',
                  height: '40px',
               },
            },
         },
      },
      MuiTextField: {
         styleOverrides: {
            root: {
               '& .MuiOutlinedInput-root': {
                  height: '40px',
                  color: '#ffffff',
                  border: 'solid 1px #e0c78d',
                  '& > input': {
                     // '&:-webkit-autofill': {
                     //    boxShadow: '0 0 0 1000px #1b1b1b inset !important',
                     //    backgroundColor: 'transparent !important',
                     // },
                     padding: '10px 13px',
                     color: '#ffffff',
                  },
               },
            },
         },
      },
      MuiSvgIcon: {
         styleOverrides: {
            root: ({ theme }) => ({
               color: theme.palette.grey[500],
            }),
         },
      },
   },
});

