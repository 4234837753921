import React from 'react';

import websocket from '../lib/Websocket';

const useWebsocket = (): void => {
   React.useEffect(() => {
      void websocket.connect();

      return () => {
         websocket.disconnect();
      };
   }, []);
};

export default useWebsocket;
