import React from 'react';

import { Box, BoxProps, styled } from '@mui/material';

export const MainWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='main' mt={7.5} {...props}>
      {children}
   </Box>
))(() => ({
   width: '100vw',
   height: 'calc(100vh - 60px)',
   display: 'flex',
}));
