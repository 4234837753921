/* eslint-disable no-console */
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const preview = {
   submit: async (formData: FormData) => {
      const url = baseUrl + 'estimator/files/upload/scodixai';
      const accessToken = sessionStorage.getItem('accessToken');
      console.log('DATA:' + formData);
      await axios.post(url, formData, {
         headers: {
            //"Content-Type": data.type,
            Authorization: `Bearer ${accessToken}`, // Add Bearer token here
         },
      });
   },

   fetchThreeJSImage: async (fileName: string, filePath: string) => {
      const url =
         baseUrl +
         `estimator/files/download?bucket=eu-scodix-ai-output-jobs&filename=${filePath}/${fileName}`;
      const accessToken = sessionStorage.getItem('accessToken');
      try {
         const response = await axios.get(url, {
            headers: {
               Authorization: `Bearer ${accessToken}`,
               'Content-Type': "'application/octet-stream'",
            },
            responseType: 'arraybuffer',
         });
         return response;
      } catch (error) {
         console.error('Fetching ThreeJSColoredImg error:', error);
         throw error;
      }
   },

   testClick: async (connectionId: string) => {
      try {
         const response = await axios.post('http://3.78.66.144/dev_test', {
            connection_id: connectionId,
         });

         return response.data;
      } catch (error) {
         console.error('Test click error:', error);
         throw error;
      }
   },
};

export default preview;
