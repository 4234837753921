/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Appbar } from 'components/appbar';
import LibraryComponent from 'components/Library';
import LibraryData from 'components/Library/types';
import { TableComponent } from 'components/table';
import { type TableDataTypes } from 'components/table/types';
import { ThreeJSImage } from 'components/threejs-image';
import { Toolbar } from 'components/toolbar';
import { usePreviewLogic } from 'pages/Preview/preview.logic';
import { usePreviewStyles } from 'pages/Preview/preview.styles';
import { EnumImageType } from 'state/general/general.types';
import { roundToSomeDigits } from 'utils';

import useWebsocket from '../../hooks/useWebsocket';
import { MainWrapper } from '../../styles/styles';

export default function Preview() {
   useWebsocket();

   const { contentWrapper, Image, imageBox, AnimatedGrid, toolbarWrapper, mainContentWrapper, tableWrapper } =
      usePreviewStyles();

   const {
      tableData,
      handleInputChange,
      tableNumberOfCopies,
      selectedImageView,
      decodedThreeJSData,
      imageTypeUrl,
      isProgressCmykImage,
      isTableVisible,
      isLibraryVisible,
      isProgressOtherImages,
      isAnimationOff,
      uploadingHelperText,
      handlers,
   } = usePreviewLogic();

   const COLUMNS = [
      {
         id: 111,
         header: 'Mode',
         renderItem: (item: TableDataTypes) => <Typography component='p'>{item.mode}</Typography>,
      },
      {
         id: 222,
         header: 'Per Copy',
         renderItem: (item: TableDataTypes) => (
            <Typography component='p'>{item.perCopy ? `$${item.perCopy}` : ''}</Typography>
         ),
      },
      {
         id: 333,
         header: 'Copies',
         withInputField: true,
         onChange: handleInputChange,
         value: tableNumberOfCopies,
         renderItem: (item: TableDataTypes) => (
            <Typography component='p'>
               {item.numberOfCopies ? `$${roundToSomeDigits(item.perCopy * tableNumberOfCopies)}` : ''}
            </Typography>
         ),
      },
   ];

   const uploading = () => (
      <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
         <CircularProgress />
         <Typography>{uploadingHelperText}</Typography>
      </Box>
   );

   // State to manage the visibility of the library based on URL parameter
   const [showLibrary, setShowLibrary] = useState(false);

   useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const levelValue = params.get('level');

      if (levelValue === 'dev') {
         setShowLibrary(true);
      } else {
         setShowLibrary(true);
      }
   }, []); // Run once when the component mounts

   return (
      <>
         <Appbar />
         <MainWrapper>
            <Box component='section' sx={toolbarWrapper}>
               <Toolbar />
            </Box>
            <Box component='section' gap={1} sx={mainContentWrapper}>
               <Box component='div' sx={contentWrapper}>
                  {selectedImageView === 'Embellishment Options' ? (
                     isProgressCmykImage ? (
                        uploading()
                     ) : imageTypeUrl[EnumImageType.CMYK] ? (
                        <AnimatedGrid isanimationoff={isAnimationOff ? 'off' : 'on'}>
                           <Image
                              src={imageTypeUrl[EnumImageType.CMYK]}
                              sx={{ opacity: isAnimationOff ? 1 : 0.5 }}
                           />
                        </AnimatedGrid>
                     ) : null
                  ) : null}

                  {selectedImageView === 'Text & Objects' ? (
                     isProgressOtherImages ? (
                        uploading()
                     ) : imageTypeUrl[EnumImageType.Merge] ? (
                        <Box sx={imageBox}>
                           <Image src={imageTypeUrl[EnumImageType.Merge]} />
                        </Box>
                     ) : null
                  ) : null}

                  {selectedImageView === 'text' ? (
                     isProgressOtherImages ? (
                        uploading()
                     ) : imageTypeUrl[EnumImageType.OCR] ? (
                        <Box sx={imageBox}>
                           <Image src={imageTypeUrl[EnumImageType.OCR]} />
                        </Box>
                     ) : null
                  ) : null}

                  {selectedImageView === 'objects' ? (
                     isProgressOtherImages ? (
                        uploading()
                     ) : imageTypeUrl[EnumImageType.Segmentation] ? (
                        <Box sx={imageBox}>
                           <Image src={imageTypeUrl[EnumImageType.Segmentation]} />
                        </Box>
                     ) : null
                  ) : null}

                  {selectedImageView === 'Preview' ? (
                     <ThreeJSImage
                        printColor={decodedThreeJSData.printColor}
                        printBW={decodedThreeJSData.printBW}
                        //printColor={process.env.PUBLIC_URL + '/env/1.png'}
                        //printBW={process.env.PUBLIC_URL + '/env/11.png'}
                        matCap={process.env.PUBLIC_URL + '/matcaps/006.exr'}
                        envTex={process.env.PUBLIC_URL + '/env/environment.hdr'}
                     />
                  ) : null}
               </Box>
               {isTableVisible ? (
                  <Box component='div' sx={tableWrapper}>
                     <TableComponent tableData={tableData} tableSetup={COLUMNS} />
                     <Button variant='outlined' onClick={handlers.handleTestClick}>
                        Test click
                     </Button>
                  </Box>
               ) : null}

               {isLibraryVisible && showLibrary ? (
                  <Box component='div' sx={tableWrapper}>
                     <LibraryComponent
                        LibraryData={LibraryData}
                        onCellClick={handlers.handleLibraryCellClick}
                     />
                  </Box>
               ) : null}
            </Box>
         </MainWrapper>
      </>
   );
}
