import React from 'react';

import { AppBar, Avatar, Typography } from '@mui/material';

export const Appbar = () => {
   return (
      <AppBar position='fixed' className='PrimaryAppbar'>
         <Typography component='p' className='ScodixAI'>
            Scodix AI
         </Typography>
         <Avatar className='UserAvatar'>E</Avatar>
      </AppBar>
   );
};
