import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LibraryComponentProps } from './types';

const LibraryComponent: React.FC<LibraryComponentProps> = ({ LibraryData, onCellClick }) => {
   return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
         <TableContainer
            component={Paper}
            style={{ background: '#1b1b1b', maxWidth: '300px', border: '1px solid lightgray' }}
         >
            <Table sx={{ minWidth: 200 }} aria-label='scodix-ai1 table'>
               <TableHead>
                  <TableRow>
                     <TableCell align='left' sx={{ padding: '12px' }}>
                        <Box style={{ color: '#fff' }} gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                           ID
                        </Box>
                     </TableCell>
                     <TableCell align='left' sx={{ padding: '12px' }}>
                        <Box style={{ color: '#fff' }} gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                           Jobs Library
                        </Box>
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {LibraryData.length > 0 &&
                     LibraryData.map(
                        (value, index) =>
                           value.visible && (
                              <TableRow
                                 key={value.id}
                                 sx={{
                                    '&:hover': {
                                       backgroundColor: 'rgba(0, 0, 8, 8)', // Light black hover effect
                                    },
                                    borderBottom:
                                       index === 2 || (index === 5 && index !== LibraryData.length - 1)
                                          ? '6px solid'
                                          : '',
                                    borderColor:
                                       index === 2
                                          ? '#0b0b0b'
                                          : index === 5 && index !== LibraryData.length - 1
                                          ? '#0b0b0b'
                                          : '',
                                 }}
                              >
                                 <TableCell style={{ color: '#fff' }} align='left' sx={{ padding: '12px' }}>
                                    {index + 1}
                                 </TableCell>
                                 <TableCell
                                    style={{ color: '#fff', cursor: 'pointer' }}
                                    align='left'
                                    sx={{ padding: '12px' }}
                                    onClick={() => onCellClick(value.job)}
                                 >
                                    {value.job}
                                 </TableCell>
                              </TableRow>
                           ),
                     )}
               </TableBody>
            </Table>
         </TableContainer>
      </div>
   );
};

export default LibraryComponent;
