// store.ts
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import generalReducer from '../state/general/general.slice';
import flagReducer from '../state/reducers';

const generalPersistConfig = {
   key: 'general',
   storage,
   whitelist: ['blackPxText'],
};

const reducer = combineReducers({
   flag: flagReducer, // you did
   general: generalReducer,
});

const store = configureStore({
   reducer,
   devTools: process.env.NODE_ENV !== 'production',
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
export default store;
