/* eslint-disable no-console */
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import api from 'api';
import { JobFolder } from 'components/Library/types';
import { TableDataTypes } from 'components/table/types';
import {
   setDecodedImageData,
   setImageTypeUrl,
   setNumberOfCopies,
   stateOfGeneralSlice,
} from 'state/general/general.slice';
import { EnumImageType, JOB_STATUS } from 'state/general/general.types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { convertBinaryToImage, roundToSomeDigits } from 'utils';

import { imageSizeMap, initialTableData } from './mock';

export const usePreviewLogic = () => {
   const dispatch = useAppDispatch();
   const {
      settings,
      blackPxText,
      tableNumberOfCopies,
      selectedImageView,
      imageTypeUrl,
      decodedThreeJSData,
      isTableVisible,
      isLibraryVisible,
   } = useAppSelector(stateOfGeneralSlice);
   const { jobStatus, folderName, connectionId } = settings;

   const [tableData, setTableData] = useState<TableDataTypes[]>(initialTableData);

   const baseUrl = `https://eu-scodix-ai-output-jobs.s3.eu-central-1.amazonaws.com`;

   const progressValue = useMemo(() => {
      const jobs = Object.values(JOB_STATUS).length - 1;
      const currentJob = Object.values(JOB_STATUS).indexOf(jobStatus);
      return (currentJob / jobs) * 100;
   }, [jobStatus]);

   // const isProgressCmykImage = jobStatus !== JOB_STATUS.INITIAL && jobStatus !== JOB_STATUS.AI_FINISHED;
   const isProgressCmykImage =
      jobStatus === JOB_STATUS.UPLOADING ||
      jobStatus === JOB_STATUS.RIP_PROGRESS ||
      jobStatus === JOB_STATUS.CALCULATED_SUCCESSFULLY ||
      jobStatus === JOB_STATUS.START_AI;

   const isProgressOtherImages =
      jobStatus === JOB_STATUS.UPLOADING ||
      jobStatus === JOB_STATUS.RIP_PROGRESS ||
      jobStatus === JOB_STATUS.CALCULATED_SUCCESSFULLY ||
      jobStatus === JOB_STATUS.START_AI ||
      jobStatus === JOB_STATUS.CMYK_READY ||
      jobStatus === JOB_STATUS.END_OCR ||
      jobStatus === JOB_STATUS.END_SEGMENTATION;

   const uploadingHelperText =
      jobStatus === JOB_STATUS.UPLOADING ? 'UPLOADING IMAGE....' : 'IN RIP PROCESS....';

   const isAnimationOff = jobStatus === JOB_STATUS.AI_FINISHED;

   const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (Number(e.target.value) > 10000) {
         return;
      } else {
         dispatch(setNumberOfCopies(Number(e.target.value)));
      }
   };

   // update Table data
   useEffect(() => {
      const coefficientMap = {
         black_px_merge: 0.0000000022946,
         black_px_text: 0.0000000055165,
         black_px_segmentation: 0.0000000055165,
      };

      if (blackPxText && Object.keys(blackPxText).length > 0) {
         const newTableData = initialTableData.map((item) => {
            const multiplyMethod = item.multiplyMethod;
            const coefficient = coefficientMap[multiplyMethod];

            const value = blackPxText[multiplyMethod];
            const perCopy = roundToSomeDigits(Number(value) * coefficient);
            const visible = Boolean(value);

            return {
               numberOfCopies: perCopy,
               id: item.id,
               mode: item.mode,
               perCopy,
               visible,
            };
         });
         setTableData(newTableData as TableDataTypes[]);
      }
   }, [blackPxText]);

   // Function to handle cell clicks
   const handleLibraryCellClick = (job: JobFolder) => {
      const setImageUrls = (basePath: string) => {
         dispatch(setImageTypeUrl({ imageTypeUrl: EnumImageType.CMYK, url: `${basePath}/Cmyk.png` }));
         dispatch(
            setImageTypeUrl({ imageTypeUrl: EnumImageType.Merge, url: `${basePath}/Merge_results.png` }),
         );
         dispatch(setImageTypeUrl({ imageTypeUrl: EnumImageType.OCR, url: `${basePath}/OCR.png` }));
         dispatch(
            setImageTypeUrl({
               imageTypeUrl: EnumImageType.Segmentation,
               url: `${basePath}/Segmentation.png`,
            }),
         );
         dispatch(
            setDecodedImageData({
               printColor: `${basePath}/Cmyk.png`,
               printBW: `${basePath}/Merge_results.png`,
            }),
         );
      };

      switch (job) {
         case JobFolder.KOA:
            setImageUrls('env/Koa');
            break;
         case JobFolder.CARD_3:
            setImageUrls('env/Card3');
            break;
         case JobFolder.INFINITY_BOX:
               setImageUrls('env/Infinity_Box');
               break;
         case JobFolder.LOVE_CARD:
               setImageUrls('env/Love_Card');
               break;
         case JobFolder.ICELAND_BOOK:
               setImageUrls('env/Scodix_ICELAND_Book');
               break;
         case JobFolder.SCODIX_PROVEN:
            setImageUrls('env/Scodix_Proven');
            break;
         case JobFolder.THANK_YOU_CARD:
               setImageUrls('env/Thank_You_Card');
               break;
         case JobFolder.STOLEN_BANKER_BOOK:
            setImageUrls('env/Stolen_Banker');
               break;    
         case JobFolder.WEDDING_1:
            setImageUrls('env/Wedding_1');
               break;  
         case JobFolder.WEDDING_2:
            setImageUrls('env/Wedding_2');
               break;  
         case JobFolder.WEDDING_3:
            setImageUrls('env/Wedding_3');
               break;  
         case JobFolder.WEDDING_4:
            setImageUrls('env/Wedding_4');
               break;       
         default:
            break;
      }
   };

   // get and convert image when JOB_STATUS.A3_READY
   useEffect(() => {
      const getThreeJSData = async () => {
         try {
            const { data: printColor } = await api.preview.fetchThreeJSImage('Cmyk.png', folderName);
            const { data: printBw } = await api.preview.fetchThreeJSImage('Merge_results.png', folderName);

            const convertedColoredImage = await convertBinaryToImage(printColor);
            const convertedBWImage = await convertBinaryToImage(printBw);
            dispatch(
               setDecodedImageData({
                  printColor: convertedColoredImage,
                  printBW: convertedBWImage,
               }),
            );
         } catch (error) {
            console.error('Get encoded image error');
         }
      };
      if (jobStatus === JOB_STATUS.AI_FINISHED && folderName) {
         getThreeJSData();
      }
   }, [jobStatus, folderName]);

   // depending on JobStatus fill image url
   useEffect(() => {
      if (!folderName) return;

      let imageType: EnumImageType;
      let imageName = '';

      switch (jobStatus) {
         // case JOB_STATUS.END_OCR:
         //    imageType = EnumImageType.OCR;
         //    imageName = imageSizeMap.default.ocr;
         //    break;
         // case JOB_STATUS.END_SEGMENTATION:
         //    imageType = EnumImageType.Segmentation;
         //    imageName = imageSizeMap.default.segmentation;
         //    break;
         // case JOB_STATUS.END_MERGE:
         //    imageType = EnumImageType.Merge;
         //    imageName = imageSizeMap.default.merge;
         //    break;
         case JOB_STATUS.CMYK_READY:
            imageType = EnumImageType.CMYK;
            imageName = imageSizeMap.default.cmyk;
            break;
         // if cmyk event is missing and currently its missing. Trigger image when AIGeneratedSuccessfully
         case JOB_STATUS.AI_FINISHED:
            imageType = EnumImageType.CMYK;
            imageName = imageSizeMap.default.cmyk;
            break;
         default:
            return;
      }

      setTimeout(() => {
         dispatch(
            setImageTypeUrl({
               imageTypeUrl: imageType,
               url: new URL(`${baseUrl}/${folderName}/${imageName}`).toString(),
            }),
         );
      }, 1000);
   }, [jobStatus]);

   useEffect(() => {
      if (folderName) {
         setTimeout(() => {
            dispatch(
               setImageTypeUrl({
                  imageTypeUrl: EnumImageType.OCR,
                  url: new URL(`${baseUrl}/${folderName}/${imageSizeMap.default.ocr}`).toString(),
               }),
            );
         }, 1000);
      }
   }, [folderName]);

   useEffect(() => {
      if (folderName) {
         setTimeout(() => {
            dispatch(
               setImageTypeUrl({
                  imageTypeUrl: EnumImageType.Segmentation,
                  url: new URL(`${baseUrl}/${folderName}/${imageSizeMap.default.segmentation}`).toString(),
               }),
            );
         }, 1000);
      }
   }, [folderName]);

   useEffect(() => {
      if (folderName) {
         setTimeout(() => {
            dispatch(
               setImageTypeUrl({
                  imageTypeUrl: EnumImageType.Merge,
                  url: new URL(`${baseUrl}/${folderName}/${imageSizeMap.default.merge}`).toString(),
               }),
            );
         }, 1000);
      }
   }, [folderName]);

   // DEMO

   const handleTestClick = () => {
      api.preview.testClick(connectionId);
   };

   // const demoUrl =
   //    'https://eu-scodix-ai-output-jobs.s3.eu-central-1.amazonaws.com/card_Eyal_Test_Tb3qAd91FiACE5w=.tif/';

   // depending on selected Radio change ImageType and its url
   // useEffect(() => {
   //    if (!folderName) return;

   //    const baseUrl = `https://eu-scodix-ai-output-jobs.s3.eu-central-1.amazonaws.com/${folderName}`;

   //    if (['default', 'B1', 'B2'].includes(fileSizeTypes)) {
   //       const sizeTypeMap = imageSizeMap[fileSizeTypes];

   //       dispatch(
   //          setImageTypeUrl({
   //             imageTypeUrl: EnumImageType.OCR,
   //             url: new URL(`${baseUrl}/${sizeTypeMap.ocr}`).toString(),
   //          }),
   //       );

   //       dispatch(
   //          setImageTypeUrl({
   //             imageTypeUrl: EnumImageType.Segmentation,
   //             url: new URL(`${baseUrl}/${sizeTypeMap.segmentation}`).toString(),
   //          }),
   //       );

   //       dispatch(
   //          setImageTypeUrl({
   //             imageTypeUrl: EnumImageType.Merge,
   //             url: new URL(`${baseUrl}/${sizeTypeMap.merge}`).toString(),
   //          }),
   //       );
   //    }
   // }, [fileSizeTypes, dispatch]);

   return {
      tableData,
      progressValue,
      handleInputChange,
      tableNumberOfCopies,
      selectedImageView,
      decodedThreeJSData,
      imageTypeUrl,
      isTableVisible,
      isLibraryVisible,
      isProgressCmykImage,
      isProgressOtherImages,
      isAnimationOff,
      uploadingHelperText,
      data: {
         jobStatus,
      },
      handlers: {
         handleTestClick,
         handleLibraryCellClick,
      },
   };
};
