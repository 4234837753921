/* eslint-disable no-console */
import React, { ComponentRef, useEffect, useRef } from 'react';

import GUI from 'lil-gui'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { type ThreeJSImageProps } from './types';

export const ThreeJSImage = ({ printColor, printBW, matCap, envTex }: ThreeJSImageProps) => {
   const threeContainer = useRef<ComponentRef<'div'>>(null);
   let scene: THREE.Scene,
      camera: THREE.PerspectiveCamera,
      renderer: THREE.WebGLRenderer,
      controls: OrbitControls,
      mesh: THREE.Mesh,
      glass: THREE.Mesh,
      material: THREE.MeshStandardMaterial,
      physicalMaterial: THREE.MeshPhysicalMaterial,
      blurredTexture: THREE.Texture,
      invertedTexture: THREE.Texture


   let imgSizes = {
      width: 0,
      height: 0
   }


   const GENERAL = {
      bumpScale: 0,
      displacementScale: 0.02,
      flatShading: true,
   }

   const Physical = {
      transparent: true,
      opacity: 0.9,
      depthWrite: false,
      color: 0xffffff,
      emissive: 0x000000,
      roughness: 0.07,
      metalness: 0.07,
      // ior: 1.20,
      reflectivity: 0.96,
      iridescence: 0,
      iridescenceIOR: 1.3,
      sheen: 0,
      sheenRoughness: 1,
      sheenColor: 0x000000,
      clearcoat: 0.4,
      clearcoatRoughness: 0.4,
      specularIntensity: 0.25,
      specularColor: 0xffffff,
      flatShading: true,
      transmission: 1,
      thickness: 0.01,
      attenuationColor: 0xffffff,
      attenuationDistance: 0.00001,
      bumpScale: 0,
      displacementScale: 0.02,
      envMapIntensity: 1.75,
   }

   useEffect(() => {
      const init = () => {
         if (!threeContainer.current) return;

         scene = new THREE.Scene();

         // Camera
         camera = new THREE.PerspectiveCamera(
            30,
            threeContainer.current.clientWidth / threeContainer.current.clientHeight,
            0.001,
            1000,
         );
         camera.position.set(0, 0, 10);
         camera.lookAt(scene.position);
         scene.add(camera);

         // Renderer
         renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            depth: true
         });
         renderer.setSize(threeContainer.current.clientWidth, threeContainer.current.clientHeight);
         renderer.setClearColor(0x0b0b0b);
         renderer.toneMappingExposure = 1
         threeContainer.current.appendChild(renderer.domElement);

         // Controls
         controls = new OrbitControls(camera, renderer.domElement);
         controls.addEventListener('change', render);
         controls.enableZoom = true;
         controls.enablePan = false;
         // controls.maxPolarAngle = Math.PI/2; // locks vertical rotation
         // controls.minPolarAngle = Math.PI / 2;
         // controls.maxPolarAngle = 3.0;
         controls.maxAzimuthAngle = Math.PI / 2;
         controls.minAzimuthAngle = -Math.PI / 2;
         // controls.enableDamping = true;
         // controls.dampingFactor = 0.05; // rubbery inertia
         controls.rotateSpeed = 0.75;

         // loading manager
         const manager = new THREE.LoadingManager(() => {
            animate();
         });

         // matcap loader
         //const loaderEXR = new EXRLoader(manager);
         //const matcap_01 = loaderEXR.load(matCap);
         const rgbeLoader = new RGBELoader(manager);
         rgbeLoader.load(envTex, function (texEnv: THREE.Texture) {
            texEnv.mapping = THREE.EquirectangularReflectionMapping;
            scene.environment = texEnv;
         });

         // Image - texture loader & texture attributes
         const textureLoader = new THREE.TextureLoader(manager);
         textureLoader.crossOrigin = '';

         const texColor = textureLoader.load(printColor);
         // const texBW = textureLoader.load(printBW);
         
         const texBW = textureLoader.load(printBW, (texture) => {
            imgSizes = {
               width: texture.image.width,
               height: texture.image.height
            }

            const canvas1 = document.createElement('canvas');
            const ctx = canvas1.getContext('2d')!;
   
            // Set canvas size equal to image size
            canvas1.width = imgSizes.width;
            canvas1.height = imgSizes.height;
   
            // Draw the image onto the canvas
            ctx.drawImage(texture.image, 0, 0);
   
            ctx.filter = 'blur(10px)'; 
            ctx.drawImage(canvas1, 0, 0);

            blurredTexture = new THREE.CanvasTexture(canvas1);

            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas1.width, canvas1.height);

            ctx.globalCompositeOperation = 'difference';

            ctx.drawImage(texture.image, 0, 0);
            ctx.globalCompositeOperation = 'source-over';


            invertedTexture = new THREE.CanvasTexture(canvas1);
        });

        manager.onLoad = function () {
         add3DImage(texColor, texBW);
         add_lights()

         const params = new URLSearchParams(window.location.search);

         const levelValue = params.get('level');

         if (levelValue === 'dev') {
            settings();
         }
        }

         texColor.anisotropy = texBW.anisotropy = 16;
         texColor.colorSpace = 'srgb';
         texColor.minFilter = texBW.minFilter = 1008;
         texColor.magFilter = texBW.magFilter = 1006;


         window.addEventListener('resize', onWindowResize);

         animate();
      };

      function add_lights () {
         const light = new THREE.AmbientLight(0xffffff)
         scene.add(light)
       
         // const d_light2 = new THREE.DirectionalLight(0xffffff, 1)
         // d_light2.position.set(-2, -2, 3)
         // // scene.add(d_light2)
       
         // const d_light3 = new THREE.DirectionalLight(0xffffff, 1)
         // d_light3.position.set(-3, -3, 4)
         // scene.add(d_light3)
       }



      function add3DImage(texColor: THREE.Texture, texBW: THREE.Texture) {
         const ceil = 512;
         let geometry;

         if (imgSizes.width > imgSizes.height) {
            const aspect = imgSizes.height / imgSizes.width
            geometry = new THREE.PlaneGeometry(6, 6 * aspect, Math.floor(ceil / aspect), ceil);
         } else if (imgSizes.width < imgSizes.height) {
            const aspect = imgSizes.width / imgSizes.height
            geometry = new THREE.PlaneGeometry(6 * aspect, 6, ceil, Math.floor(ceil / aspect));
         } else {
            geometry = new THREE.PlaneGeometry(6, 6, ceil, ceil);
         }
         


         material = new THREE.MeshStandardMaterial({
            map: texColor,
            // roughnessMap: texBW,
            bumpMap: texBW,
            displacementMap: blurredTexture,
            ...GENERAL,
         });

         mesh = new THREE.Mesh(
            geometry,
            material
         )

         scene.add(mesh)


         physicalMaterial = new THREE.MeshPhysicalMaterial({
            ...Physical,
            displacementMap: blurredTexture,
            alphaMap: invertedTexture,
            ior: 1.4
         });

         glass = new THREE.Mesh(
            geometry,
            physicalMaterial
         )

         scene.add(glass)

      }

      function settings () {
         const gui = new GUI();
       
         const general1 = gui.addFolder('Placeholder');
         general1.close();
         const general = gui.addFolder('Settings');
         // general.close();
         const physical = gui.addFolder('Physical');
         // physical.close();

         general.add(GENERAL, 'bumpScale', -1, 1).step(0.01).onChange((value: number) => {
            material.bumpScale = value
         })

         general.add(GENERAL, 'displacementScale', -0.2, 0.2).step(0.001).onChange((value: number) => {
            material.displacementScale = value
         })


         general.add(GENERAL, 'flatShading').onChange((value: boolean) => {
            material.flatShading = value
            material.needsUpdate = true
         })

        
         // Physical
         physical.add(Physical, 'transparent').onChange((value: boolean) => {
            physicalMaterial.transparent = value
         })

         physical.add(Physical, 'opacity', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.opacity = value
         })


         physical.add(Physical, 'depthWrite').onChange((value: boolean) => {
            physicalMaterial.depthWrite = value
         })

         physical.addColor(Physical, 'color').onChange((value: string | number | THREE.Color) => {
            physicalMaterial.color.set(value)
         })

         physical.addColor(Physical, 'emissive').onChange((value: string | number | THREE.Color) => {
            physicalMaterial.emissive.set(value)
         })

         physical.add(Physical, 'roughness', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.roughness = value
         })

         physical.add(Physical, 'metalness', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.metalness = value
         })


         // physical.add(Physical, 'ior', 1, 2.33).step(0.01).onChange((value: number) => {
         //    physicalMaterial.ior = value
         // })

         physical.add(Physical, 'reflectivity', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.reflectivity = value
         })

         physical.add(Physical, 'iridescence', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.iridescence = value
         })

         physical.add(Physical, 'iridescenceIOR', 1, 2.33).step(0.01).onChange((value: number) => {
            physicalMaterial.iridescenceIOR = value
         })

         physical.add(Physical, 'sheen', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.sheen = value
         })

         physical.add(Physical, 'sheenRoughness', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.sheenRoughness = value
         })

         physical.addColor(Physical, 'sheenColor').onChange((value: string | number | THREE.Color) => {
            physicalMaterial.sheenColor.set(value)
         })

         physical.add(Physical, 'clearcoat', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.clearcoat = value
         })

         physical.add(Physical, 'clearcoatRoughness', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.clearcoatRoughness = value
         })

         physical.add(Physical, 'specularIntensity', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.specularIntensity = value
         })

         physical.addColor(Physical, 'specularColor').onChange((value: string | number | THREE.Color) => {
            physicalMaterial.specularColor.set(value)
         })

         physical.add(Physical, 'flatShading').onChange((value: boolean) => {
            physicalMaterial.flatShading = value
            physicalMaterial.needsUpdate = true
         })

         physical.add(Physical, 'transmission', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.transmission = value
         })

         physical.add(Physical, 'thickness', 0, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.thickness = value
         })

         physical.addColor(Physical, 'attenuationColor').onChange((value: string | number | THREE.Color) => {
            physicalMaterial.attenuationColor.set(value)
         })

         physical.add(Physical, 'attenuationDistance', 0, 0.001).step(0.00001).onChange((value: number) => {
            physicalMaterial.attenuationDistance = value
         })

         physical.add(Physical, 'bumpScale', -1, 1).step(0.01).onChange((value: number) => {
            physicalMaterial.bumpScale = value
         })

         physical.add(Physical, 'displacementScale', -0.2, 0.2).step(0.001).onChange((value: number) => {
            physicalMaterial.displacementScale = value
         })

         physical.add(Physical, 'envMapIntensity', 0, 2).step(0.001).onChange((value: number) => {
            physicalMaterial.envMapIntensity = value
         })





      }

      const animate = () => {
         requestAnimationFrame(animate);
         controls.update();
         render()
      };

      function render() {
         renderer.render(scene, camera);
      }

      function onWindowResize() {
         if (threeContainer.current) {
            renderer.setSize(threeContainer.current?.clientWidth, threeContainer.current.clientHeight);

            camera.aspect = threeContainer.current.clientWidth / threeContainer.current.clientHeight;
            camera.updateProjectionMatrix();
         }
      }

      init();

      return () => {
         if (threeContainer.current) {
            threeContainer.current.removeChild(renderer.domElement);
         }
      };
   }, [printColor, printBW, matCap, envTex]);
   return <div style={{ width: '100%', height: '100%' }} ref={threeContainer} />;
};