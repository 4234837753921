import axios from 'axios';

interface userInfo {
   email: string;
   id: string;
   isAdmin: boolean;
   machines: any[];
   nickname: string | null;
   phone: string | null;
}

export interface User {
   accessToken: string;
   user: userInfo;
   exchangeRates: {
      [key: string]: {
         [key: string]: number;
      };
   };
}

export interface LoginRequestPayload {
   email: string;
   password: string;
}

const auth = {
   login: async (payload: LoginRequestPayload): Promise<User> => {
      try {
         const url = process.env.REACT_APP_API_URL + 'authentication/login';
         const response = await axios.post(url, payload);
         return response.data;
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log('Login attempt failed');
         throw error; // Rethrow the caught error
      }
   },
};

export default auth;
