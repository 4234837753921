export interface GeneralState {
   flag: boolean;
   settings: Settings;
   blackPxText: BlackPxTextTypes | null;
   tableNumberOfCopies: number;
   fileSizeTypes: FileSizeTypes;
   decodedThreeJSData: ThreeJSDataTypes;
   selectedImageView: SelectedImageView;
   imageTypeUrl: ImageTypeURL;
   isTableVisible: boolean;
   isLibraryVisible: boolean;
}

export enum JOB_STATUS {
   INITIAL = 'Initial',
   UPLOADING = 'Uploading',
   RIP_PROGRESS = 'InRipProgress',
   CALCULATED_SUCCESSFULLY = 'CalculatedSuccessfully',
   START_AI = 'StartAI',
   CMYK_READY = 'CMYKReady',
   END_OCR = 'EndOCR',
   END_SEGMENTATION = 'EndSegmentation',
   END_MERGE = 'EndMerge',
   A3_READY = 'A3Ready',
   AI_FINISHED = 'AIGeneratedSuccessfully',
}

export interface Settings {
   connectionId: string;
   folderName: string;
   jobStatus: JOB_STATUS;
}

export interface BlackPxTextTypes {
   black_px_merge: string;
   black_px_segmentation: string;
   black_px_text: string;
   total_px: string;
   up_to_sheet_A3: string;
   up_to_sheet_B1: string;
   up_to_sheet_B2: string;
}

export type FileSizeTypes = 'default' | 'B1' | 'B2';

export type ThreeJSDataTypes = {
   printColor: string;
   printBW: string;
};

export type SelectedImageView =
   | 'Embellishment Options'
   | 'Text & Objects'
   | 'text'
   | 'objects'
   | 'Preview'
   | '';

export enum EnumImageType {
   Merge = 'merge',
   OCR = 'ocr',
   Segmentation = 'segmentation',
   CMYK = 'cmyk',
   Foil = 'foil'
}

export type ImageTypeURL = {
   [EnumImageType.Merge]: string;
   [EnumImageType.OCR]: string;
   [EnumImageType.Segmentation]: string;
   [EnumImageType.CMYK]: string;
   [EnumImageType.Foil]: string;
};
