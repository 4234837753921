import React from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import Login from 'pages/Login';
import Preview from 'pages/Preview';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { scodixAiTheme } from 'styles/theme';

import AuthRoute from './routes/authRoute';
import PrivateRoute from './routes/privateRoute';

function App() {
   return (
      <ThemeProvider theme={scodixAiTheme}>
         <CssBaseline />
         <Routes>
            {/* <Route path="/" element={<Navigate replace to="/login" />} /> */}
            <Route path='/' element={<AuthRoute component={Login} />} />
            <Route path='/preview' element={<PrivateRoute component={Preview} />} />
         </Routes>
         <ToastContainer />
      </ThemeProvider>
   );
}

export default App;
