import { useEffect, useRef } from 'react';

import preview from 'api/preview';
import {
   changeLibraryVisibility,
   changeTableVisibility,
   resetDecodedImageData,
   resetImageTypeUrl,
   selectImageView,
   stateOfGeneralSlice,
   updateSettings,
} from 'state/general/general.slice';
import { JOB_STATUS, type SelectedImageView } from 'state/general/general.types';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useToolbarLogic = () => {
   const inputRef = useRef<HTMLInputElement | null>(null);
   const dispatch = useAppDispatch();
   const { settings, selectedImageView, isTableVisible, isLibraryVisible } = useAppSelector(stateOfGeneralSlice);
   const { jobStatus } = settings;

   const isFileUploading = jobStatus !== JOB_STATUS.INITIAL && jobStatus !== JOB_STATUS.AI_FINISHED;

   const handleSelectImageView = (view: SelectedImageView) => {
      if (isFileUploading) return;
      dispatch(selectImageView(view));
   };

   //  const [dragIsOver, setDragIsOver] = useState(false);
   //  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
   //     event.preventDefault();
   //     setDragIsOver(true);
   //  };

   //  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
   //     event.preventDefault();
   //     setDragIsOver(false);
   //  };

   //  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
   //     event.preventDefault();
   //     const files = event.dataTransfer.files;
   //     const formData = new FormData();
   //     // eslint-disable-next-line no-console
   //     console.log('handleDrop');
   //     for (let i = 0; i < files.length; i++) {
   //        const file = files[i];
   //        const filenameParts = file.name.split('.');
   //        const extension = filenameParts[filenameParts.length - 1];
   //        const fileBody = [...filenameParts];
   //        fileBody.pop();
   //        const nameWithConnectionId = `${fileBody.join('.')}_${settings.connectionId}.${extension}`;
   //        const finalFile = new File([file], nameWithConnectionId, { type: file.type });
   //        formData.append('file', finalFile);
   //        // eslint-disable-next-line no-console
   //        console.log(finalFile);
   //     }
   //     dispatch(updateSettings({ jobStatus: JOB_STATUS.UPLOADING }));
   //     preview.submit(formData);
   //     //dispatch(sendFileToServer(formData));
   //     setDragIsOver(false);
   //  };

   const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const files = event.target.files;
      // eslint-disable-next-line no-console
      console.log('handleFileInputChange');
      if (files) {
         // eslint-disable-next-line no-console
         console.log('handle-------');
         dispatch(resetImageTypeUrl());
         dispatch(resetDecodedImageData());
         const formData = new FormData();
         for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const filenameParts = file.name.split('.');
            const extension = filenameParts[filenameParts.length - 1];
            const fileBody = [...filenameParts];
            fileBody.pop();
            const nameWithConnectionId = `${fileBody.join('.')}_${settings.connectionId}.${extension}`;
            const finalFile = new File([file], nameWithConnectionId, { type: file.type });
            formData.append('file', finalFile);
            // eslint-disable-next-line no-console
            console.log(finalFile);
         }
         dispatch(updateSettings({ jobStatus: JOB_STATUS.UPLOADING }));
         preview.submit(formData);
         //dispatch(sendFileToServer(formData));
      }
   };

   useEffect(() => {
      if (jobStatus !== JOB_STATUS.AI_FINISHED) dispatch(selectImageView('Embellishment Options'));
   }, [jobStatus]);

   const handleInputClick = () => {
      if (inputRef && inputRef.current) {
         inputRef.current.click();
      }
   };

   const handleChangeTableVisibility = () => {
      dispatch(changeTableVisibility(!isTableVisible));
   };

   const handleChangeLibraryVisibility = () => {
      dispatch(changeLibraryVisibility(!isLibraryVisible));
   };

   return {
      handleInputClick,
      inputRef,
      handleFileInputChange,
      handleSelectImageView,
      selectedImageView,
      handleChangeTableVisibility,
      handleChangeLibraryVisibility,
      isTableVisible,
      isLibraryVisible,
      isFileUploading,
   };
};
