import { Box, BoxProps } from '@mui/material';
import { styled, type SxProps, Theme } from '@mui/material/styles';

interface ChangeImageViewButtonProps extends BoxProps<'button'> {
   selected: boolean;
}

export const useToolbarStyles = () => {
   const toolbarBox: SxProps<Theme> = ({ shadows }) => ({
      borderRadius: '15px',
      boxShadow: shadows[5],
      padding: '16px',
      marginLeft: '1.25rem',
      marginRight: '1.25rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
      width: '100%',
   });

   const toolbarButton: SxProps = {
      padding: '0.5rem 0.75rem',
      borderRadius: '0.75rem',
      cursor: 'pointer',
      outlineColor: '#e5e7eb',
      outlineStyle: 'solid',
      outlineWidth: '2.5px',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      position: 'relative',
      userSelect: 'none',
      '&:hover': {
         outlineColor: '#e0c78d',
      },
   };

   const parentButtonLine: SxProps = {
      '&::after': {
         content: '""',
         position: 'absolute',
         top: '103%',
         borderLeft: '2px solid #e5e7eb',
         height: '135px',
      },
   };

   const childrenButtonLine: SxProps = {
      marginLeft: '32px',
      '&::after': {
         content: '""',
         position: 'absolute',
         left: '-10.6%',
         border: '1px solid #e5e7eb',
         width: '18px',
      },
   };

   const ChangeImageViewButton = styled(({ children, ...props }: ChangeImageViewButtonProps) => (
      <Box component='button' {...props}>
         {children}
      </Box>
   ))(({ disabled, selected }) => ({
      padding: '0.5rem 0.75rem',
      borderRadius: '0.75rem',
      cursor: disabled ? 'auto' : 'pointer',
      outlineColor: selected && !disabled ? '#e0c78d' : '#e5e7eb',
      outlineStyle: 'solid',
      outlineWidth: '2.5px',
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      background: '#0b0b0b',
      border: 'none',
      opacity: disabled ? 0.5 : 1,
      position: 'relative',
      '&:hover': {
         outlineColor: disabled ? '#e5e7eb' : '#e0c78d',
      },
   }));

   return {
      toolbarBox,
      toolbarButton,
      ChangeImageViewButton,
      style: {
         parentButtonLine,
         childrenButtonLine,
      },
   };
};
