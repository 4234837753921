export function roundToSomeDigits(value: number, toDigits = 3): number {
   return Number(value.toFixed(toDigits));
}

export async function convertBinaryToImage(arrayBuffer: ArrayBuffer, bufferSize = 1024): Promise<string> {
   return new Promise((resolve, reject) => {
      try {
         const binaryData = new Uint8Array(arrayBuffer);
         const concatenatedData = new Uint8Array(binaryData.length);

         for (let i = 0; i < binaryData.length; i += bufferSize) {
            const chunk = binaryData.subarray(i, i + bufferSize);
            concatenatedData.set(chunk, i);
         }

         const blob = new Blob([concatenatedData], { type: 'image/png' });
         const url = URL.createObjectURL(blob);

         resolve(url);
      } catch (error) {
         reject(error);
      }
   });
}
