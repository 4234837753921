// reducer.ts
import { TOGGLE_FLAG } from './actionTypes';

type Action = {
   type: string;
};

const initialState = false;

const flagReducer = (state = initialState, action: Action) => {
   switch (action.type) {
      case TOGGLE_FLAG:
         return !state;
      default:
         return state;
   }
};

export default flagReducer;
