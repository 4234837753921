import { Box, BoxProps, styled, SxProps } from '@mui/material';

interface AnimatedGridProps extends BoxProps {
   isanimationoff?: 'on' | 'off';
}

export const usePreviewStyles = () => {
   const contentWrapper: SxProps = {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      width: '69%',
   };

   const imageBox: SxProps = {
      height: '100%',
      width: '100%',
      position: 'relative',
   };

   const toolbarWrapper: SxProps = {
      display: 'flex',
      alignItems: 'center',
      width: '18rem',
   };

   const mainContentWrapper: SxProps = {
      display: 'flex',
      padding: '2rem',
      width: 'calc(100% - 18rem)',
   };

   const tableWrapper: SxProps = {
      width: '30%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
   };

   const Image = styled('img')({
      width: '100%',
      position: 'absolute',
      display: 'block',
      maxWidth: '100%',
      height: '100%',
   });

   const AnimatedGrid = styled(({ children, ...props }: AnimatedGridProps) => (
      <Box component='div' {...props}>
         {children}
      </Box>
   ))(({ isanimationoff }) => ({
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      position: 'relative',
      '::after': {
         content: '""',
         position: 'absolute',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundSize: '20px 20px',
         pointerEvents: 'none',
         backgroundImage: `${
            isanimationoff === 'off'
               ? 'none'
               : 'radial-gradient(circle at 3.5px 3.5px, white 3.5px, transparent 3.5px), radial-gradient(circle at 3.5px 3.5px, white 3.5px, transparent 3.5px)'
         }`,
         //  animation: 'flicker 12s ease-in-out 0s infinite, disappear 1s forwards 12s',
         animation: `${isanimationoff === 'off' ? 'none' : 'flicker 3s ease-in-out 0s infinite'}`,
      },
      '@keyframes flicker': {
         '0%': {
            opacity: 1,
         },
         '50%': {
            opacity: 0,
         },
         '100%': {
            opacity: 1,
         },
      },
      '@keyframes disappear': {
         '0%': {
            opacity: 1,
         },
         '100%': {
            opacity: 0,
         },
      },
   }));

   return {
      contentWrapper,
      Image,
      AnimatedGrid,
      imageBox,
      toolbarWrapper,
      mainContentWrapper,
      tableWrapper,
   };
};
