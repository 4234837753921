import React from 'react';

import {
   Box,
   Paper,
   SxProps,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
} from '@mui/material';

import { type TableComponentProps } from './types';

const textFieldNum: SxProps = {
   '& input': {
      color: '#fff',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
         WebkitAppearance: 'none',
         margin: 0,
      },
   },
};

export const TableComponent = ({ tableSetup, tableData }: TableComponentProps) => {
   return (
      <TableContainer component={Paper} style={{background: '#1b1b1b'}}>
         <Table sx={{ minWidth: 380 }} aria-label='scodix-ai table'>
            <TableHead>
               <TableRow>
                  {tableSetup.map(({ header, id, align, withInputField, onChange, value }) => (
                     <TableCell key={id} align={align || 'left'} sx={{ padding: '12px' }}>
                        <Box style={{color: '#fff'}} gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                           {header}
                           {withInputField && (
                              <TextField
                                 type='number'
                                 sx={{ ...textFieldNum, width: '7ch' }}
                                 value={value}
                                 onChange={onChange}
                                 variant='standard'
                              />
                           )}
                        </Box>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {tableData.length > 0 &&
                  tableData.map(
                     (value, index) =>
                        value.visible && (
                           <TableRow
                              key={value.id}
                              aria-label={value.multiplyMethod}
                              sx={{
                                 borderBottom:
                                    index === 2 || (index === 5 && index !== tableData.length - 1)
                                       ? '6px solid'
                                       : '',
                                 borderColor:
                                    index === 2
                                       ? '#0b0b0b'
                                       : index === 5 && index !== tableData.length - 1
                                       // ? '#808080'
                                       ? '#0b0b0b'
                                       : '',
                              }}
                           >
                              {tableSetup.map(({ renderItem, id, align }) => (
                              
                              <TableCell style={{color: '#fff'}} key={id} align={align || 'left'} sx={{ padding: '12px' }}>
                                    {renderItem(value)}
                                 </TableCell>
                              ))}
                           </TableRow>
                        ),
                  )}
            </TableBody>
         </Table>
      </TableContainer>
   );
};
